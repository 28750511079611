type CurrencyOptions = {
  showCents: "always" | "never" | "when-non-zero";
  negativeAsZero?: boolean;
};

export const formatCurrency = (
  number: number,
  locale: string,
  options: Partial<CurrencyOptions> = {}
) => {
  const opts: CurrencyOptions = {
    showCents: "always",
    ...options,
  };

  let maxDigits = 2;
  let minDigits = 2;

  switch (opts.showCents) {
    case "never":
      maxDigits = 0;
      minDigits = 0;
      break;
    case "when-non-zero":
      if (number % 1 === 0) {
        maxDigits = 0;
        minDigits = 0;
      } else {
        maxDigits = 2;
        minDigits = 2;
      }
      break;
    case "always":
    default:
      maxDigits = 2;
      minDigits = 2;
      break;
  }

  let value = number;
  if (opts.negativeAsZero && number < 0) {
    value = 0;
  }

  const localeString = locale === "fr" ? "fr-CA" : "en-CA";
  const formattedNumber = value.toLocaleString(localeString, {
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: minDigits,
    style: "currency",
    currency: "CAD",
  });

  return formattedNumber;
};

export const formatPercent = (
  number: number,
  locale: string,
  maxDigits?: number
) => {
  const percent = number / 100;
  const localeString = locale === "fr" ? "fr-CA" : "en-CA";
  return percent.toLocaleString(localeString, {
    style: "percent",
    maximumFractionDigits: maxDigits ?? 2,
  });
};

export const formatNumber = (number: number, locale: string) => {
  const localeString = locale === "fr" ? "fr-CA" : "en-CA";
  return number.toLocaleString(localeString, {
    maximumFractionDigits: 2,
  });
};
